import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserEnum } from '../../types/UserType';
import { VersionType } from '../../types/Versions';

interface PlaybackAuthState {
  token: string;
  userType: UserEnum;
  email: string;
  isAuthenticated: boolean;
  isVideoProcessed: boolean;
  version: VersionType;
  videoUrl: string;
  resumeUrl: string;
  meetingData: { meetingId: string; meetingTitle: string };
  isAdmin: boolean;
  hasAdminApproved: boolean;
  hasRequested: boolean;
  isInterviewer: boolean;
  requestedBy: string;
  requestedAt: string;
  validUser: boolean;
  recruiterName: string;
  isConsultancy: boolean;
  recruitmentCompany: string;
  accessCode: string;
  isPlaybackServiceEnabled: boolean;
  internal: boolean;
  companyCurrentSaasPlan: string;
  companyOldSaasPlan: string;
  companyCountry: string;
  recordVideo: boolean;
  videoWasEnabled: boolean;
  daysFromConducted: number;
  trimDetails:
    | {
        by: string;
        editTime: string;
        requestTime: string;
      }
    | false;
}

const initialState: PlaybackAuthState = {
  token: '',
  userType: UserEnum.DEFAULT,
  email: '',
  isAuthenticated: false,
  version: 'v3',
  resumeUrl: '',
  videoUrl: '',
  meetingData: { meetingId: '', meetingTitle: '' },
  isVideoProcessed: false,
  isAdmin: false,
  hasAdminApproved: false,
  hasRequested: false,
  isInterviewer: false,
  requestedAt: '',
  requestedBy: '',
  validUser: false,
  recruiterName: '',
  isConsultancy: false,
  recruitmentCompany: '',
  accessCode: '',
  isPlaybackServiceEnabled: false,
  companyCurrentSaasPlan: '',
  companyOldSaasPlan: '',
  companyCountry: '',
  internal: false,
  recordVideo: true,
  videoWasEnabled: true,
  daysFromConducted: 0,
  trimDetails: false,
};

export const playbackAuthSlice = createSlice({
  name: 'playbackAuth',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setUserType: (state, action: PayloadAction<UserEnum>) => {
      state.userType = action.payload;
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
    setIsVideoProcessed: (state, action: PayloadAction<boolean>) => {
      state.isVideoProcessed = action.payload;
    },
    setVersion: (
      state,
      action: PayloadAction<PlaybackAuthState['version']>
    ) => {
      state.version = action.payload;
    },
    setResumeUrl: (state, action: PayloadAction<string>) => {
      state.resumeUrl = action.payload;
    },
    setVideoUrl: (state, action: PayloadAction<string>) => {
      state.videoUrl = action.payload;
    },
    setMeetingData: (
      state,
      action: PayloadAction<PlaybackAuthState['meetingData']>
    ) => {
      state.meetingData = action.payload;
    },
    setIsAdmin: (state, action: PayloadAction<boolean>) => {
      state.isAdmin = action.payload;
    },
    setHasAdminApproved: (state, action: PayloadAction<boolean>) => {
      state.hasAdminApproved = action.payload;
    },
    setHasRequested: (state, action: PayloadAction<boolean>) => {
      state.hasRequested = action.payload;
    },
    setIsInterviewer: (state, action: PayloadAction<boolean>) => {
      state.isInterviewer = action.payload;
    },
    setRequestedBy: (state, action: PayloadAction<string>) => {
      state.requestedBy = action.payload;
    },
    setRequestedAt: (state, action: PayloadAction<string>) => {
      state.requestedAt = action.payload;
    },
    setValidUser: (state, action: PayloadAction<boolean>) => {
      state.validUser = action.payload;
    },
    setRecruiterName: (state, action: PayloadAction<string>) => {
      state.recruiterName = action.payload;
    },
    setIsConsultancy: (state, action: PayloadAction<boolean>) => {
      state.isConsultancy = action.payload;
    },
    setRecruitmentCompany: (state, action: PayloadAction<string>) => {
      state.recruitmentCompany = action.payload;
    },
    setPlaybackAccessCode: (state, action: PayloadAction<string>) => {
      state.accessCode = action.payload;
    },
    setIsPlaybackServiceEnabled: (state, action: PayloadAction<boolean>) => {
      state.isPlaybackServiceEnabled = action.payload;
    },
    setInternal: (state, action: PayloadAction<boolean>) => {
      state.internal = action.payload;
    },
    setCompanyCurrentSaasPlan: (state, action: PayloadAction<string>) => {
      state.companyCurrentSaasPlan = action.payload;
    },
    setCompanyOldSaasPlan: (state, action: PayloadAction<string>) => {
      state.companyOldSaasPlan = action.payload;
    },
    setCompanyCountry: (state, action: PayloadAction<string>) => {
      state.companyCountry = action.payload;
    },
    setRecordVideo: (state, action: PayloadAction<boolean>) => {
      state.recordVideo = action.payload;
    },
    setVideoWasEnabled: (state, action: PayloadAction<boolean>) => {
      state.videoWasEnabled = action.payload;
    },
    setDaysFromConducted: (state, action: PayloadAction<number>) => {
      state.daysFromConducted = action.payload;
    },
    setTrimDetails: (
      state,
      action: PayloadAction<{
        by: string;
        editTime: string;
        requestTime: string;
      }>
    ) => {
      state.trimDetails = action.payload;
    },
  },
});

export const {
  setToken,
  setEmail,
  setUserType,
  setIsAuthenticated,
  setIsVideoProcessed,
  setVersion,
  setResumeUrl,
  setMeetingData,
  setVideoUrl,
  setIsAdmin,
  setHasAdminApproved,
  setHasRequested,
  setIsInterviewer,
  setRequestedBy,
  setRequestedAt,
  setValidUser,
  setRecruiterName,
  setIsConsultancy,
  setRecruitmentCompany,
  setPlaybackAccessCode,
  setIsPlaybackServiceEnabled,
  setCompanyCurrentSaasPlan,
  setCompanyOldSaasPlan,
  setCompanyCountry,
  setInternal,
  setRecordVideo,
  setVideoWasEnabled,
  setDaysFromConducted,
  setTrimDetails,
} = playbackAuthSlice.actions;

export default playbackAuthSlice.reducer;
