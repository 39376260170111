import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ITabState {
  tabId: string;
  runSampleTests?: {
    testCasesWithTokens?: {
      test_case_id: string;
      input: string;
      output: string;
      token: string;
    }[];
    code?: string;
    isLoading?: boolean;
    languageId?: string;
  };
  runHiddenTests?: {
    testCasesWithTokens?: {
      test_case_id: string;
      input: string;
      output: string;
      token: string;
    }[];
    code?: string;
    isLoading?: boolean;
    languageId?: string;
  };

  generateQuestions?: {
    token?: string[] | null;
    attempt?: number;
    isLoading?: boolean;
  };
  runCode?: {
    codeRunToken?: string | null;
    code?: string;
    isLoading?: boolean;
    languageId?: string;
    framework?: number;
  };
  isWhiteBoardOpen?: boolean;
  leftAside?: {
    isQuestionsActive?: boolean;
    isImportQuestionsOpen?: boolean;
  };
}

const initialState: {
  activeTab: string;
  code: string;
  getCode: string;
  tabs: ITabState[];
  userLog: { id: string; color?: string | undefined }[];
  rightAside: {
    openTab: 'IO' | 'FEEDBACK' | 'TEST_CASES' | 'NOTES' | 'HISTORY' | '';
  };
  video: {
    isMaximized: boolean;
    expandedId: string;
    isContentExpanded: boolean;
  };
} = {
  activeTab: '',
  code: '',
  getCode: '',
  userLog: [],
  tabs: [],
  rightAside: {
    openTab: 'IO',
  },
  video: {
    isMaximized: false,
    isContentExpanded: false,
    expandedId: '',
  },
};

export const tabsStateSlice = createSlice({
  name: 'tabsState',
  initialState,
  reducers: {
    setActiveTab: (state, action: PayloadAction<string>) => {
      const isTabPresent = state.tabs.find(
        (tab) => tab.tabId === action.payload
      );

      state.activeTab = action.payload;
      if (!isTabPresent) {
        state.tabs.push({
          tabId: action.payload,
          runCode: {
            codeRunToken: null,
          },
        });
      }
    },
    removeTab: (state, action: PayloadAction<string>) => {
      state.tabs = state.tabs.filter((tab) => tab.tabId !== action.payload);
    },
    updateRunCode: (state, action: PayloadAction<ITabState['runCode']>) => {
      const tabToUpdate = state.tabs.find(
        (tab) => tab.tabId === state.activeTab
      );

      if (tabToUpdate && tabToUpdate.runCode) {
        tabToUpdate.runCode = { ...tabToUpdate.runCode, ...action.payload };
      }
    },
    updateSampleRunTests: (
      state,
      action: PayloadAction<ITabState['runSampleTests']>
    ) => {
      const tabToUpdate = state.tabs.find(
        (tab) => tab.tabId === state.activeTab
      );

      if (tabToUpdate) {
        tabToUpdate.runSampleTests = {
          ...tabToUpdate.runSampleTests,
          ...action.payload,
        };
      }
    },
    updateHiddenRunTests: (
      state,
      action: PayloadAction<ITabState['runHiddenTests']>
    ) => {
      const tabToUpdate = state.tabs.find(
        (tab) => tab.tabId === state.activeTab
      );

      if (tabToUpdate) {
        tabToUpdate.runHiddenTests = {
          ...tabToUpdate.runHiddenTests,
          ...action.payload,
        };
      }
    },
    toggleWhiteBoard: (state) => {
      const tabToUpdate = state.tabs.find(
        (tab) => tab.tabId === state.activeTab
      );

      if (tabToUpdate) {
        tabToUpdate.isWhiteBoardOpen = !tabToUpdate.isWhiteBoardOpen;
      }
    },
    updateLeftAsideTabState: (
      state,
      action: PayloadAction<ITabState['leftAside']>
    ) => {
      const tabToUpdate = state.tabs.find(
        (tab) => tab.tabId === state.activeTab
      );

      if (tabToUpdate) {
        tabToUpdate.leftAside = { ...tabToUpdate.leftAside, ...action.payload };
      }
    },
    updateRightAsideTabState: (
      state,
      action: PayloadAction<{
        openTab: 'IO' | 'FEEDBACK' | 'TEST_CASES' | 'NOTES' | 'HISTORY' | '';
      }>
    ) => {
      state.rightAside = action.payload;
    },
    updateUserLog: (
      state,
      action: PayloadAction<{ id: string; color?: string | undefined }[]>
    ) => {
      state.userLog = action.payload;
    },
    maximizeVideo: (state, action: PayloadAction<{ attendeeId: string }>) => {
      state.video = {
        ...state.video,
        isMaximized: true,
        expandedId: action.payload.attendeeId,
        isContentExpanded: false,
      };
    },
    minimizeVideo: (state) => {
      state.video = { ...state.video, isMaximized: false, expandedId: '' };
    },
    minimizeContent: (state) => {
      state.video = { ...state.video, isContentExpanded: false };
    },
    maximizeContent: (state) => {
      state.video = {
        ...state.video,
        isContentExpanded: true,
        isMaximized: false,
        expandedId: '',
      };
    },
    // This Updates The Editor Code Be Careful when using this
    updateCode: (state, action: PayloadAction<string>) => {
      state.code = action.payload;
    },
    // This just contains the latest value of editor code
    updateGetCode: (state, action: PayloadAction<string>) => {
      state.getCode = action.payload;
    },
    updateGenerateQuestions: (
      state,
      action: PayloadAction<ITabState['generateQuestions']>
    ) => {
      const tabToUpdate = state.tabs.find(
        (tab) => tab.tabId === state.activeTab
      );

      if (tabToUpdate) {
        tabToUpdate.generateQuestions = {
          ...tabToUpdate.generateQuestions,
          ...action.payload,
        };
      }
    },
  },
});

export const {
  maximizeVideo,
  minimizeVideo,
  maximizeContent,
  minimizeContent,
  setActiveTab,
  updateRunCode,
  updateSampleRunTests,
  updateHiddenRunTests,
  removeTab,
  updateLeftAsideTabState,
  toggleWhiteBoard,
  updateRightAsideTabState,
  updateUserLog,
  updateCode,
  updateGetCode,
  updateGenerateQuestions,
} = tabsStateSlice.actions;

export default tabsStateSlice.reducer;
