import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface MeetingData {
  meetingId: string;
  meetingTitle: string;
}

interface MeetingState {
  meetingData: MeetingData;
  isMeetingEnded: boolean;
  meetingName: string;
  candidateName: string;
  interviewerName: string;
  candidatePhone: string;
  interviewerPhone: string;
  showRating: boolean;
  candidateRemoved: boolean;
}

const initialState: MeetingState = {
  meetingData: { meetingId: '', meetingTitle: '' },
  isMeetingEnded: false,
  meetingName: '',
  candidateName: '',
  interviewerName: '',
  candidatePhone: '',
  interviewerPhone: '',
  showRating: false,
  candidateRemoved: false,
};

export const meetingSlice = createSlice({
  name: 'meeting',
  initialState,
  reducers: {
    setMeetingData: (state, action: PayloadAction<MeetingData>) => {
      state.meetingData = action.payload;
    },
    setIsMeetingEnded: (state, action: PayloadAction<boolean>) => {
      state.isMeetingEnded = action.payload;
    },
    setMeetingName: (state, action: PayloadAction<string>) => {
      state.meetingName = action.payload;
    },
    setCandidateName: (state, action: PayloadAction<string>) => {
      state.candidateName = action.payload;
    },
    setInterviewerName: (state, action: PayloadAction<string>) => {
      state.interviewerName = action.payload;
    },
    setCandidatePhone: (state, action: PayloadAction<string>) => {
      state.candidatePhone = action.payload;
    },
    setInterviewerPhone: (state, action: PayloadAction<string>) => {
      state.interviewerPhone = action.payload;
    },
    setShowRating: (state, action: PayloadAction<boolean>) => {
      state.showRating = action.payload;
    },
    setCandidateRemoved: (state, action: PayloadAction<boolean>) => {
      state.candidateRemoved = action.payload;
    },
  },
});

export const {
  setMeetingData,
  setIsMeetingEnded,
  setMeetingName,
  setCandidateName,
  setInterviewerName,
  setCandidatePhone,
  setShowRating,
  setInterviewerPhone,
  setCandidateRemoved,
} = meetingSlice.actions;

export default meetingSlice.reducer;
