import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EventV2Type } from '../../types/EventType.model';

interface PlaybackV2State {
  events: EventV2Type[];
  codeHistory: any;
  userIdMap: any;
  recordingStartTime: number | null;
  tags: { title: string; timestamp: number }[];
}

const initialState: PlaybackV2State = {
  events: [],
  codeHistory: [],
  userIdMap: {},
  recordingStartTime: null,
  tags: [],
};

export const playbackV2Slice = createSlice({
  name: 'playbackv2',
  initialState,
  reducers: {
    setEvents: (state, action: PayloadAction<any[]>) => {
      const parsedEvents: EventV2Type[] = [];
      for (const item in action.payload) {
        const parsedItem = { ...action.payload[item] };
        parsedEvents.push(parsedItem);
      }
      const sortedEvents = parsedEvents.sort(
        (item1, item2) => item1.timestamp - item2.timestamp
      );
      state.events = sortedEvents;
    },
    setCodeHistory: (
      state,
      action: PayloadAction<PlaybackV2State['codeHistory']>
    ) => {
      const events = action.payload;
      const newCodeHistory: any = [];
      for (const item in events) {
        newCodeHistory.push(events[item]);
      }
      const sortedEvents = newCodeHistory.sort(
        (item1: any, item2: any) => item1.t - item2.t
      );
      state.codeHistory = sortedEvents;
    },
    setUserIdMap: (
      state,
      action: PayloadAction<PlaybackV2State['userIdMap']>
    ) => {
      state.userIdMap = action.payload;
    },
    setRecordingStartTime: (
      state,
      action: PayloadAction<PlaybackV2State['recordingStartTime']>
    ) => {
      state.recordingStartTime = action.payload;
    },
    setTags: (state, action: PayloadAction<any>) => {
      const snapshots = action.payload;
      const tags: PlaybackV2State['tags'] = [];
      for (const item in snapshots) {
        tags.push(snapshots[item]);
      }
      state.tags = tags;
    },
  },
});

export const {
  setEvents,
  setCodeHistory,
  setUserIdMap,
  setRecordingStartTime,
  setTags,
} = playbackV2Slice.actions;

export default playbackV2Slice.reducer;
