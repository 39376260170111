import classes from './index.module.scss';
import HunchVueLogo from '../../assets/images/HunchVueMainLogo.svg';
import DemoEnded from '../../assets/images/DemoEnded.svg';
import { Buffer } from 'buffer';
import { thankYouPageMessages } from '../../constants/messages';
import { useAppSelector } from '../../hooks/useAppSelector';

const app_url = process.env.REACT_APP_HH_APP_URL;

const DemoThankYouPage = () => {
  const authState = useAppSelector((state) => state.auth);
  const handleRegister = () => {
    window.open(
      `${app_url}company/register?em=${Buffer.from(
        authState.email,
        'utf-8'
      ).toString('base64')}&tff=1`
    );
  };

  return (
    <section className={classes.container}>
      <img className={classes.logo} src={HunchVueLogo} alt="HunchVue" />
      <span className={classes.thankYouText}>
        {thankYouPageMessages.demoEndedTitle}
      </span>

      <span className={classes.thankYouMessage}>
        {thankYouPageMessages.demoEndedMessage}
      </span>
      <img
        className={classes.loadingImage}
        src={DemoEnded}
        alt="HunchVue Demo"
      />
      <button
        id="activate_trial_exit_screen"
        className={classes.activateButton}
        onClick={handleRegister}
      >
        ACTIVATE FREE TRIAL
      </button>
    </section>
  );
};

export default DemoThankYouPage;
