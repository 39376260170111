import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface RecordingState {
  isRecording: boolean;
}

const initialState: RecordingState = {
  isRecording: false,
};

export const recordingSlice = createSlice({
  name: 'recording',
  initialState,
  reducers: {
    setRecording: (
      state,
      action: PayloadAction<RecordingState['isRecording']>
    ) => {
      state.isRecording = action.payload;
    },
  },
});

export const { setRecording } = recordingSlice.actions;

export default recordingSlice.reducer;
