import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CompetencyModel } from '../../components/Competencies/models/FeedbackForm';

export type feedbackId =
  | 'questionsDiscussed'
  | 'positives'
  | 'negatives'
  | 'overallFeedback';

export enum LoadingState {
  SAVING = 'Saving...',
  SAVED = 'Saved',
  FAILED = 'Failed',
  SYNCING = 'Syncing...',
  NA = '',
}

export interface SkillTagModel {
  id: number;
  tag: string;
}

interface CustomFeedbackState {
  loadingState: LoadingState;
  data: {
    competencies: CompetencyModel[];
    competencyError: boolean;
    sliderError: boolean;
    editableForm: boolean;
    oldFeedbackForm: boolean;
    questionsAsked: string[];
    questionsDiscussed: string[];
    noteToInterviewer: string;
    noteFromHH: string;
    overallComment: string;
    uploadedFiles: { fileName: string; filePath: string }[];
    recommendation: number | null;
    consentChecked: boolean;
    skills: SkillTagModel[];
  };
  toggleQuestions: boolean;
}

const initialState: CustomFeedbackState = {
  loadingState: LoadingState.NA,
  data: {
    competencies: [],
    competencyError: false,
    sliderError: false,
    editableForm: false,
    oldFeedbackForm: false,
    questionsAsked: [],
    questionsDiscussed: [],
    noteToInterviewer: '',
    noteFromHH: '',
    overallComment: '',
    uploadedFiles: [],
    recommendation: null,
    consentChecked: false,
    skills: [],
  },
  toggleQuestions: false,
};

export const customFeedbackSlice = createSlice({
  name: 'customFeedback',
  initialState,
  reducers: {
    setCompetencies: (state, action: PayloadAction<CompetencyModel[]>) => {
      state.data.competencies = action.payload;
    },
    setCompetencyError: (state, action: PayloadAction<boolean>) => {
      state.data.competencyError = action.payload;
    },
    setSliderError: (state, action: PayloadAction<boolean>) => {
      state.data.sliderError = action.payload;
    },
    setEditableForm: (state, action: PayloadAction<boolean>) => {
      state.data.editableForm = action.payload;
    },
    setOldFeedbackForm: (state, action: PayloadAction<boolean>) => {
      state.data.oldFeedbackForm = action.payload;
    },
    setConsentChecked: (state, action: PayloadAction<boolean>) => {
      state.data.consentChecked = action.payload;
    },
    setCustomToggleQuestionsAsked: (state, action: PayloadAction<boolean>) => {
      state.toggleQuestions = action.payload;
    },
    setLoadingState: (state, action: PayloadAction<LoadingState>) => {
      state.loadingState = action.payload;
    },
    setQuestionsAsked: (state, action: PayloadAction<string[]>) => {
      state.data.questionsAsked = action.payload;
    },
    setQuestionsDiscussed: (state, action: PayloadAction<string[]>) => {
      state.data.questionsDiscussed = action.payload;
    },
    setNoteToInterviewer: (state, action: PayloadAction<string>) => {
      state.data.noteToInterviewer = action.payload;
    },
    setNoteFromHH: (state, action: PayloadAction<string>) => {
      state.data.noteFromHH = action.payload;
    },
    setOverallComment: (state, action: PayloadAction<string>) => {
      state.data.overallComment = action.payload;
    },
    setSkills: (state, action: PayloadAction<SkillTagModel[]>) => {
      state.data.skills = action.payload;
    },
    setUploadedFiles: (
      state,
      action: PayloadAction<{ fileName: string; filePath: string }[]>
    ) => {
      state.data.uploadedFiles = action.payload;
    },
    setRecommendation: (state, action: PayloadAction<number | null>) => {
      state.data.recommendation = action.payload;
    },
  },
});

export const {
  setCompetencies,
  setCompetencyError,
  setSliderError,
  setEditableForm,
  setOldFeedbackForm,
  setLoadingState,
  setQuestionsAsked,
  setNoteToInterviewer,
  setNoteFromHH,
  setOverallComment,
  setUploadedFiles,
  setRecommendation,
  setConsentChecked,
  setSkills,
  setQuestionsDiscussed,
  setCustomToggleQuestionsAsked,
} = customFeedbackSlice.actions;

export default customFeedbackSlice.reducer;
