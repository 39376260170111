import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface candidateActivity {
  title: 'TAB_SWITCHED' | 'CODE_PASTED';
  showToast: boolean;
  timestamp: number;
}
const initialState: candidateActivity[] = [];

export const candidateActivitySlice = createSlice({
  name: 'candidateActivity',
  initialState,
  reducers: {
    setCandidateActivity: (
      state,
      action: PayloadAction<candidateActivity[]>
    ) => {
      return [...action.payload];
    },
    setActivityClosed: (state, action: PayloadAction<number>) => {
      state = state.map((item) => {
        if (item.timestamp === action.payload) {
          item.showToast = false;
        }
        return item;
      });
    },
  },
});

export const { setCandidateActivity, setActivityClosed } =
  candidateActivitySlice.actions;

export default candidateActivitySlice.reducer;
