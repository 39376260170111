import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Note {
  noteFromCompany: string;
  noteFromHirehunch: string;
  remind: boolean;
  noteExist: boolean;
  feedbackChanged: boolean;
}

const initialState: Note = {
  noteFromCompany: '',
  noteFromHirehunch: '',
  remind: false,
  noteExist: false,
  feedbackChanged: false,
};

export const interviewerNoteSlice = createSlice({
  name: 'notes',
  initialState,
  reducers: {
    setNotes: (state, action: PayloadAction<Note>) => {
      return action.payload;
    },
    setInterviewerReminded: (state) => {
      return {
        ...state,
        remind: false,
      };
    },
  },
});

export const { setNotes, setInterviewerReminded } =
  interviewerNoteSlice.actions;

export default interviewerNoteSlice.reducer;
