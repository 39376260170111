export enum TypeOfEvent {
  CODERUN = 'CR',
  LANGAUGECHANGE = 'LC',
  CODEADDED = 'CA',
  INTERVIEWENDED = 'IE',
  INTERVIEWSTARTED = 'IS',
  CODEPASTED = 'CP',
  TABSWITCHED = 'TS', // This is the Event for code editor tab switch proctor event
  TABCHANGE = 'TC', // This is the actual Tabs
  TESTSRESULT = 'TR',
  QUESTIONADDED = 'QA',
  JOINED = 'JN',
  MICSTATECHANGED = 'MC', // Mic State Changed
  VIDEOSTATECHANGED = 'VC', // Video State Changed
  WHITEBOARDSTATECHANGED = 'WC', // Whiteboard State Changed
  CONTENTSHARESTATECHANGED = 'CS', // Screen Share State Change
}

export enum InterviewerPresent {
  NOTPRESENT = 0,
  PRESENT = 1,
}

export interface EventType {
  name: string;
  userType: string;
  type: TypeOfEvent;
  major: boolean;
  ip: InterviewerPresent;
  output: { stdout: string; stderr: string; time: string };
  language: string;
  code: string;
  pos: { cl: number; ln: number };
  timestamp: number;
}

export interface NewEventType {
  orderId: number;
  name: string;
  userType: string;
  type: TypeOfEvent;
  major: boolean;
  ip: InterviewerPresent;
  output: { stdout: string; stderr: string; time: string };
  language: string;
  code: string;
  pos: { cl: number; ln: number };
  timestamp: number;
}

export interface EventV2Type {
  name: string;
  userType: string;
  type: TypeOfEvent;
  ip: InterviewerPresent;
  output: { stdout: string; stderr: string; time: string };
  language: string;
  code: string;
  timestamp: number;
  linesPasted?: number;
}

export interface EventV4Type {
  name: string;
  activeTab: string;
  userType: string;
  type: TypeOfEvent;
  output?: { stdout: string; stderr: string; time: string };
  tests?: any[];
  language: string;
  code: string;
  timestamp: number;
  question?: any;
  linesPasted?: number;
  isMicActive?: boolean;
  isVideoActive?: boolean;
  isWhiteBoardOpened?: boolean;
  isScreenShareActive?: boolean;
}
