import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EventV4Type } from '../../types/EventType.model';

interface PlaybackV4State {
  events: EventV4Type[];
  codeHistory: any;
  userIdMap: any;
  recordingStartTime: number | null;
  tags: { title: string; timestamp: number }[];
  tabs: any;
  activeTab: string;
  isQuestionPanelOpen: boolean;
  question?: any;
}

const initialState: PlaybackV4State = {
  events: [],
  codeHistory: {},
  userIdMap: {},
  recordingStartTime: null,
  tags: [],
  tabs: {},
  activeTab: '',
  isQuestionPanelOpen: false,
  question: null,
};

export const playbackV4Slice = createSlice({
  name: 'playbackv4',
  initialState,
  reducers: {
    setTabs: (state, action: PayloadAction<any[]>) => {
      state.tabs = action.payload;
    },
    setEvents: (state, action: PayloadAction<any[]>) => {
      const parsedEvents: EventV4Type[] = [];
      for (const item in action.payload) {
        const parsedItem = { ...action.payload[item] };
        parsedEvents.push(parsedItem);
      }
      const sortedEvents = parsedEvents.sort(
        (item1, item2) => item1.timestamp - item2.timestamp
      );
      state.events = sortedEvents;
    },
    setCodeHistory: (state, action: PayloadAction<any>) => {
      const data = action.payload;
      for (const tab in data) {
        const newCodeHistory: any = [];
        for (const item in data[tab]) {
          newCodeHistory.push(data[tab][item]);
        }
        const sortedEvents = newCodeHistory.sort(
          (item1: any, item2: any) => item1.t - item2.t
        );
        state.codeHistory[tab] = sortedEvents;
      }
    },
    setUserIdMap: (
      state,
      action: PayloadAction<PlaybackV4State['userIdMap']>
    ) => {
      state.userIdMap = action.payload;
    },
    setRecordingStartTime: (
      state,
      action: PayloadAction<PlaybackV4State['recordingStartTime']>
    ) => {
      state.recordingStartTime = action.payload;
    },
    setTags: (state, action: PayloadAction<any>) => {
      const snapshots = action.payload;
      const tags: PlaybackV4State['tags'] = [];
      for (const item in snapshots) {
        tags.push(snapshots[item]);
      }
      state.tags = tags;
    },
    setIsQuestionPanelOpen: (state, action: PayloadAction<boolean>) => {
      state.isQuestionPanelOpen = action.payload;
    },
    setQuestion: (state, action: PayloadAction<any>) => {
      state.question = action.payload;
    },
    setActiveTab: (state, action: PayloadAction<string>) => {
      if (state.activeTab !== action.payload) {
        state.activeTab = action.payload;
      }
    },
  },
});

export const {
  setActiveTab,
  setEvents,
  setCodeHistory,
  setUserIdMap,
  setRecordingStartTime,
  setQuestion,
  setTags,
  setTabs,
  setIsQuestionPanelOpen,
} = playbackV4Slice.actions;

export default playbackV4Slice.reducer;
