export const generalErrorMessage = 'Oops! Something went wrong.';

export const errorPageMessages = {
  invalidLinkErrorTitle: 'Oops!',
  invalidLinkErrorMessage: 'This is an invalid link.',
  sessionNotStartedTitle: 'Session Not Started',
  sessionEndedTitle: 'Session Ended',
  unsupportedDeviceTitle: 'Device Not Supported',
  unsupportedDeviceMessage: `This link isn't supported for mobile browsers. Please open the link from an updated web browser.`,
};

export const errorBoundaryMessages = {
  boundaryText:
    'Opps! Something went wrong. This seems unusual and request you to reload the page and continue.',
};

export const authPageMessages = {
  loadingScreen: 'Validation in progress...',
  demoLoadingScreen: 'Welcome to HunchVue Demo',
  loadingScreenPlayback: 'Authorisation in progress...',
  emailEmptyError: 'Email is empty!',
  emailInvalidError: 'Email is invalid!',
  displayNameError: 'Minimum 2 characters required',
  passwordEmptyError: 'Password seems to be empty or invalid',
  accessCodeEmptyError: 'Access Code is empty !',
  accessCodeInvalidError: 'Access Code is invalid !',
};

export const homePageMessages = {
  codeEmptyRunError: `Seems, there's no runnable code`,
  serverError: `Oops! Something went wrong`,
  nudge30Minutes:
    'Coding session exceeds 30 minutes. Please make sure to cover other assessment parameters provided on the feedback form.',
};

export const thankYouPageMessages = {
  sessionEndedTitle: 'Session Ended',
  sessionEndedMessage: 'Interaction ended successfully',
  demoEndedTitle: 'Start Your 14-Day Free Trial Today!',
  demoEndedMessage:
    'Register to try out HunchVue for free and see how our platform can help improve your tech interviewing. No credit card required',
};

export const stepsInfoMessages = {
  stepOne: {
    title: 'Chat',
    content: `Click to start a conversation. In-call messages are viewed by the participants in the call and are deleted after the interview.`,
  },
  stepTwo: {
    title: 'Tag',
    content: `Click to tag this particular discussion. Proceed with the option you want to tag. This will be viewed in the playback after the interview.`,
  },
  stepThree: {
    title: 'Add Candidate Feedback',
    content:
      "Now you can add the candidate's feedback during the interview or before ending the interview. This feedback will be accessible to the recruiter on the HireHunch platform.",
  },
};

export const ratingMessages = {
  ratingTitle:
    'Hope you had a great interviewing experience on HunchVue! Share your feedback',
  ratingGoodLabel: 'Tell us what you liked the most?',
  ratingBadLabel: 'Tell us how can we improve?',
  feedbackTitle: 'Thank you for the feedback!',
};

export const assessmentReport = {
  title: 'Assessment Report',
};

export const videoErrorMessages = {
  NotReadableError: {
    title: "HunchVue can't use your camera",
    content:
      'Make sure that another application is not using your camera, you might then need to refresh your browser.',
  },
  NotFoundError: {
    title: "Can't find your camera",
    content:
      'Check your systems settings to make sure that a camera is available. If not, plug one in. You might then need to restart your browser.',
  },
};

export const HunchVueDemoMeetingTitle = 'HunchVue Demo';

export const isDemoRegex = 'ZEdocGMybHpaR1Z0Ync9PQ==';

export const defaultDemoMeetingTitle = 'ft6-b1n-dz5h';

export const demoMeetingId = '40092e82-73e5-45f2-a1d1-8e348f570f7c';

export const videoNotRecorededMessage =
  'The video playback of this interview is not available as requested by the organizer.';

export const placeHolderMessages = {
  questionsDiscussed: {
    message: 'Add questions discussed during the interview ',
  },
  feedbackMessage: {
    message: 'Add your feedback here',
  },
};

export const demoPageMessages = {
  startTrialTitle: 'Activate your 14-Day Free Trial!',
  startTrialMessage:
    'Register now to start your Free Trial and get 14 days of access to HunchVue. No credit card required',
  startTrialButton: 'ACTIVATE FREE TRIAL',
};

export const LoginMessages = {
  consultMessage:
    'I will not consult any external source. This test will be completed on my own based on logic and reasoning alone.',
  instructionsTitle: 'Please read the below instructions carefully',
  cameraMessage: {
    allow: {
      title: 'This session will be recorded',
      message:
        'We will record your audio and video during this assessment, kindly allow the permissions to continue.',
    },
    enable: {
      title: 'How to Enable Camera and Microphone',
    },
    position: {
      title: 'Make Sure You are Facing the Camera',
      message:
        "By clicking on continue you accept HireHunch's terms and conditions to record your activity.",
    },
    instructions: {
      messageOne: 'Align your face to the circle',
      messageTwo: "Ensure that you're in a well lit room",
      messageThree: 'Make sure that you have proper lighting on your face',
      messageFour: "Don't position yourself at corner of the screen",
    },
  },
};

export const hunchVueV4Message = {
  lockedTabInterviewer: 'This tab is locked and not visible to candidate',
  lockedTabCandidateTooltip: 'This tab is locked and cannot be accessed.',
  disabledTabsVideoPlayback:
    'The controls have been disabled while playing video',
};

export const deleteFeedbackQuestion = {
  title: 'Delete Question?',
  message: 'Are you sure you want to remove this question from feedback form?',
};

export const questionAddPopupMessage = {
  message:
    'Adding the question will clear the editor for this tab. Would you like to use add this question in a new tab instead?',
  simpleAddMessage: 'Are you sure you want to add this question',
};
