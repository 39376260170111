import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MeetingSessionConfiguration } from 'amazon-chime-sdk-js';

export const DUMMY_RESPONSE = {
  Meeting: {
    MeetingId: 'random',
    MediaRegion: 'random',
    MediaPlacement: {
      ScreenViewingUrl: 'random',
      EventIngestionUrl: null,
      AudioFallbackUrl: 'random',
      ScreenDataUrl: 'random',
      TurnControlUrl: 'random',
      ScreenSharingUrl: 'random',
      AudioHostUrl: 'random',
      SignalingUrl: 'random',
    },
    ExternalMeetingId: null,
  },
  Attendee: {
    ExternalUserId: 'random',
    AttendeeId: 'random',
    JoinToken: 'random',
  },
};

export const meetingSessionConfiguration = new MeetingSessionConfiguration(
  DUMMY_RESPONSE.Meeting,
  DUMMY_RESPONSE.Attendee
);
interface MeetingSettingsState {
  meetingSessionDetails: any;
  blur: boolean;
  mic: boolean;
  video: boolean;
  minimize: boolean;
  meetingConfiguration: MeetingSessionConfiguration;
  activeTab: number;
}

const initialState: MeetingSettingsState = {
  meetingSessionDetails: null,
  blur: false,
  mic: true,
  video: true,
  minimize: false,
  meetingConfiguration: meetingSessionConfiguration,
  activeTab: 0,
};

export const meetingSettingsSlice = createSlice({
  name: 'meetingSettings',
  initialState,
  reducers: {
    setMeetingSessionDetails: (
      state,
      action: PayloadAction<MeetingSettingsState['meetingSessionDetails']>
    ) => {
      state.meetingSessionDetails = action.payload;
    },
    toggleMic: (state) => {
      state.mic = !state.mic;
    },
    toggleVideo: (state) => {
      state.video = !state.video;
    },
    toggleMinimize: (state) => {
      state.minimize = !state.minimize;
    },
    toggleBlur: (state) => {
      state.blur = !state.blur;
    },
    setMeetingConfiguration: (
      state,
      action: PayloadAction<MeetingSettingsState['meetingConfiguration']>
    ) => {
      state.meetingConfiguration = action.payload;
    },
    setActiveTab: (state, action: PayloadAction<number>) => {
      state.activeTab = action.payload;
    },
  },
});

export const {
  setMeetingSessionDetails,
  toggleMic,
  toggleVideo,
  toggleMinimize,
  toggleBlur,
  setMeetingConfiguration,
  setActiveTab,
} = meetingSettingsSlice.actions;

export default meetingSettingsSlice.reducer;
