import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import {
  BrowserRouter,
  useLocation,
  createRoutesFromChildren,
  matchRoutes,
  useNavigationType,
  Routes,
} from 'react-router-dom';
import './styles/index.scss';
import * as Sentry from '@sentry/react';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './store';
import { ThemeProvider } from '@mui/material';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {
  MeetingProvider,
  darkTheme,
  BackgroundBlurProvider,
} from 'amazon-chime-sdk-component-library-react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { getTheme } from './styles/theme';
import TagManager, { TagManagerArgs } from 'react-gtm-module';
import QueryClientHandler from './services/QueryClientHandler';
import { captureConsoleIntegration } from '@sentry/integrations';
import details from '../package.json';
import { useAppSelector } from './hooks/useAppSelector';

const version = details?.version ?? '0.1.0';

const tagManagerArgs: TagManagerArgs = {
  gtmId: 'GTM-KFSGQDM',
};
TagManager.initialize(tagManagerArgs);

const DSN = process.env.REACT_APP_SENTRY_DSN;

Sentry.init({
  dsn: `${DSN}`,
  enabled: `${process.env.REACT_APP_ENV}` === 'production',
  integrations: [
    captureConsoleIntegration(),
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  environment: `${process.env.REACT_APP_ENV ?? 'development'}`,
  release: version,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1,
  // release: Date.now().toString(),

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    // 'localhost',
    'https://vue.hirehunch.com',
    'https://stagingvue.hirehunch.com',
    'https://betavue.hirehunch.com',
  ],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 1,
  replaysOnErrorSampleRate: 1,
  normalizeDepth: 7,
});

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const HelmetComponent = () => {
  const authState = useAppSelector((state) => state.auth);

  // useEffect(() => {
  //   if (fullPageThreeDotsLoading > 0) {
  //     document
  //       .querySelector('.showFullLoader')
  //       ?.classList.remove('hideFullLoader');
  //   } else {
  //     document
  //       .querySelector('.showFullLoader')
  //       ?.classList.add('hideFullLoader');
  //   }
  // }, [fullPageThreeDotsLoading]);
  const title =
    authState.companyName === 'HireHunch'
      ? 'HunchVue - powered by HireHunch | Collaborative Coding & Video Interviewing Platform'
      : authState.companyName;

  return (
    <Helmet titleTemplate={title} defaultTitle={title}>
      <meta name="description" content={authState.companyName} />
    </Helmet>
  );
};

const ConnectedApp = () => {
  const [theme, setTheme] = useState(getTheme());
  useEffect(() => {
    const handleThemeUpdate = (event: any) => {
      setTheme(getTheme());
    };
    document.addEventListener('updateTheme', handleThemeUpdate);

    return () => {
      document.removeEventListener('updateTheme', handleThemeUpdate);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <QueryClientHandler>
          <BrowserRouter>
            <StyledThemeProvider theme={darkTheme}>
              <HelmetProvider>
                <HelmetComponent />
                <BackgroundBlurProvider>
                  <MeetingProvider>
                    <App />
                  </MeetingProvider>
                </BackgroundBlurProvider>
              </HelmetProvider>
            </StyledThemeProvider>
          </BrowserRouter>
        </QueryClientHandler>
      </Provider>
    </ThemeProvider>
  );
};

ReactDOM.render(<ConnectedApp />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
