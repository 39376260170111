import { useLogger } from 'amazon-chime-sdk-component-library-react';
import { LogLevel } from 'amazon-chime-sdk-js';
import { lazy, Suspense, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import {
  errorBoundaryMessages,
  HunchVueDemoMeetingTitle,
  isDemoRegex,
} from './constants/messages';
import { useAppDispatch } from './hooks/useAppDispatch';
import * as Sentry from '@sentry/react';
import { useAppSelector } from './hooks/useAppSelector';
import LoadingScreen from './screens/LoadingScreen';
import {
  setCompanyLogo,
  setCompanyName,
  setCompanyWebsite,
  setInternal,
  setIsDemo,
  setPrivacyPolicyUrl,
  setTermsOfServiceUrl,
} from './store/Auth/AuthSlice';
import { setMeetingName } from './store/Meeting/MeetingSlice';
import DemoThankYouPage from './pages/DemoThankYouPage';
import ErrorFallback from './components/ErrorFallback';
import { SentryRoutes } from '.';
import axios from 'axios';
import { BASE_URL } from './components/OverallSummary/api';
import {
  BrandDetailsLocation,
  defaultThemeProps,
  setCustomThemes,
  setFavicons,
} from './utils/helpers';

const AuthPage = lazy(() => import('./pages/AuthPage'));
const HunchVueSharePage = lazy(() => import('./pages/ShareHunchVuePage'));
// const HomeV2Page = lazy(() => import('./pages/HomeV2Page'));
// const HomeV3Page = lazy(() => import('./pages/HomeV3Page'));
const HomeV4Page = lazy(() => import('./pages/HomeV4Page'));
// const DemoV3Page = lazy(() => import('./pages/DemoV3Page'));
const PlaybackAuthPage = lazy(() => import('./pages/PlaybackAuthPage'));
const PlaybackPage = lazy(() => import('./pages/PlaybackPage'));
const PlaybackV2Page = lazy(() => import('./pages/PlaybackV2Page'));
const PlaybackV3Page = lazy(() => import('./pages/PlaybackV3Page'));
const PlaybackV4Page = lazy(() => import('./pages/PlaybackV4Page'));
const ThankYouPage = lazy(() => import('./pages/ThankYouPage'));
const ErrorPage = lazy(() => import('./pages/ErrorPage'));
const RatingPage = lazy(() => import('./pages/RatingPage'));
const OpenPage = lazy(() => import('./pages/OpenPage'));

const App = () => {
  const logger = useLogger();
  logger.setLogLevel(LogLevel.OFF);
  const dispatch = useAppDispatch();

  const search = useLocation().search;
  const isDemo = new URLSearchParams(search).get('source');

  const authState = useAppSelector((state) => state.auth);
  const meetingState = useAppSelector((state) => state.meeting);
  const playbackAuthState = useAppSelector((state) => state.playbackAuth);

  useEffect(() => {
    if (isDemo && isDemo.length > 0 && isDemo === isDemoRegex) {
      dispatch(setIsDemo(true));
      sessionStorage.setItem('isDemo', 'true');
      dispatch(setInternal(true));
      dispatch(setMeetingName(HunchVueDemoMeetingTitle));
    } else {
      sessionStorage.removeItem('isDemo');
    }
  }, []);

  const setDefaults = () => {
    setCustomThemes(
      defaultThemeProps.primary_color,
      defaultThemeProps.secondary_color
    );

    dispatch(setCompanyName('HireHunch'));
    dispatch(setCompanyWebsite(`${process.env.REACT_APP_HH_WEBSITE}`));
    dispatch(setCompanyLogo('/HunchVueMainLogo.png'));
  };

  useEffect(() => {
    const meetingParams = window.location.pathname.split('/');
    const fetchBrandSettings = async (token: string = '') => {
      try {
        const res = await axios.get(
          `${BASE_URL}/user-settings/brand-details?view=${BrandDetailsLocation.HUNCHVUE}&token=${token}`
        );
        if (!res?.data?.success) {
          window.location.href = `${window.location.origin}/error`;
          return;
        }
        if (res?.data?.data?.company_name === 'HireHunch') {
          setDefaults();
          return;
        }
        if ((res?.data?.data?.company_favicon ?? '').length > 0) {
          setFavicons(
            res?.data?.data?.company_favicon,
            res?.data?.data?.primary_color ?? defaultThemeProps.primary_color
          );
        }

        if (
          res?.data?.data?.primary_color ||
          res?.data?.data?.secondary_color
        ) {
          setCustomThemes(
            res?.data?.data?.primary_color ?? defaultThemeProps.primary_color,
            res?.data?.data?.secondary_color ??
              defaultThemeProps.secondary_color
          );
        }
        dispatch(setCompanyName(res?.data?.data?.company_name ?? 'HireHunch'));

        if ((res?.data?.data?.brand_website ?? '').length > 0) {
          dispatch(setCompanyWebsite(res?.data?.data?.brand_website));
        } else {
          dispatch(setCompanyWebsite(`${process.env.REACT_APP_HH_WEBSITE}`));
        }

        if ((res?.data?.data?.terms_of_service_url ?? '').length > 0) {
          dispatch(setPrivacyPolicyUrl(res?.data?.data?.privacy_policy_url));
          dispatch(setTermsOfServiceUrl(res?.data?.data?.terms_of_service_url));
        } else {
          dispatch(
            setPrivacyPolicyUrl(
              `${process.env.REACT_APP_HH_WEBSITE}/tnc-candidate`
            )
          );
          dispatch(
            setTermsOfServiceUrl(
              `${process.env.REACT_APP_HH_WEBSITE}/tnc-candidate`
            )
          );
        }

        if ((res?.data?.data?.brand_logo_url ?? '').length > 0) {
          dispatch(setCompanyLogo(res?.data?.data?.brand_logo_url));
        } else {
          dispatch(setCompanyLogo('/HunchVueMainLogo.png'));
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (
      meetingParams &&
      meetingParams.length >= 3 &&
      meetingParams[1].length > 0 &&
      meetingParams[meetingParams.length - 1].length > 0
    ) {
      if (meetingParams[meetingParams.length - 1] === 'share') {
        fetchBrandSettings(meetingParams[meetingParams.length - 2]);
      } else {
        fetchBrandSettings(meetingParams[meetingParams.length - 1]);
      }
    } else setDefaults();
  }, []);

  const showAuth = !authState.isAuthenticated;
  const showMeeting = authState.isAuthenticated && !meetingState.isMeetingEnded;
  const showThankYou =
    authState.isAuthenticated &&
    meetingState.isMeetingEnded &&
    !authState.isDemo &&
    !meetingState.showRating;
  const showDemoThankYou =
    authState.isAuthenticated &&
    meetingState.isMeetingEnded &&
    authState.isDemo &&
    !meetingState.showRating;
  const showRating =
    authState.isAuthenticated &&
    meetingState.isMeetingEnded &&
    meetingState.showRating;
  const showAuthPlayback = !playbackAuthState.isAuthenticated;
  const showPlayback = playbackAuthState.isAuthenticated;

  return (
    <SentryRoutes>
      <Route
        path="/try"
        element={<Suspense fallback={<>...</>}>{<OpenPage />}</Suspense>}
      />
      {showAuth && (
        <Route
          path="/:meetingTitle/:meetingId"
          element={
            <Suspense fallback={<>...</>}>
              <AuthPage />
            </Suspense>
          }
        />
      )}
      {showMeeting && (
        <Route
          path="/:meetingTitle/:meetingId"
          element={
            <Suspense
              fallback={
                <LoadingScreen message="We are setting up the environment for you. Please wait..." />
              }
            >
              {/* {authState.version === 'v2' && <HomeV2Page />} */}
              {/* {authState.version === 'v3' && !authState.isDemo && (
                  <HomeV3Page />
                )} */}
              {/* {authState.version === 'v3' && <HomeV3Page />} */}
              {authState.version === 'v4' && <HomeV4Page />}
              {/* {authState.version === 'v3' ||
                  (authState.version === 'v4' && false && <DemoV3Page />)} */}
            </Suspense>
          }
        />
      )}

      {showRating && (
        <Route
          path="/:meetingTitle/:meetingId"
          element={
            <Suspense fallback={<>...</>}>
              <RatingPage />
            </Suspense>
          }
        />
      )}

      {showThankYou && (
        <Route
          path="/:meetingTitle/:meetingId"
          element={
            <Suspense fallback={<>...</>}>
              <ThankYouPage />
            </Suspense>
          }
        />
      )}

      {showDemoThankYou && (
        <Route
          path="/:meetingTitle/:meetingId"
          element={
            <Suspense fallback={<>...</>}>
              <DemoThankYouPage />
            </Suspense>
          }
        />
      )}

      {/* The below is for Playback which is available for only Companies and Admins */}
      {showAuthPlayback && (
        <Route
          path="playback/:meetingTitle/:meetingId"
          element={
            <Suspense fallback={<>...</>}>
              <PlaybackAuthPage />
            </Suspense>
          }
        />
      )}
      {showPlayback && (
        <Route
          path="playback/:meetingTitle/:meetingId"
          element={
            <Suspense fallback={<>...</>}>
              {playbackAuthState.version === 'v1' && <PlaybackPage />}
              {playbackAuthState.version === 'v2' && <PlaybackV2Page />}
              {playbackAuthState.version === 'v3' && <PlaybackV3Page />}
              {playbackAuthState.version === 'v4' && (
                <Sentry.ErrorBoundary
                  fallback={
                    <ErrorFallback>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '50rem',
                        }}
                      >
                        {errorBoundaryMessages.boundaryText}
                      </div>
                    </ErrorFallback>
                  }
                >
                  <PlaybackV4Page />
                </Sentry.ErrorBoundary>
              )}
            </Suspense>
          }
        />
      )}
      <Route
        path="/:meetingTitle/:meetingId/share"
        element={
          <Suspense fallback={<>...</>}>
            <HunchVueSharePage />
          </Suspense>
        }
      />
      <Route
        path="*"
        element={
          <Suspense fallback={<>...</>}>
            <ErrorPage errorType="INVALID_LINK" />
          </Suspense>
        }
      />
    </SentryRoutes>
  );
};

export default App;
