import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface DemoState {
  settingsOpen: boolean;
  isLocalUserPresenting: boolean;
  landScapeModeOn: boolean;
  showDemoPopup: boolean;
  demoPopupShown: boolean;
  isDemoMaximised: boolean;
}

const initialState: DemoState = {
  settingsOpen: false,
  isLocalUserPresenting: false,
  landScapeModeOn: false,
  showDemoPopup: false,
  demoPopupShown: false,
  isDemoMaximised: false,
};

export const demoSlice = createSlice({
  name: 'demo',
  initialState,
  reducers: {
    setSettingsOpen: (state, action: PayloadAction<boolean>) => {
      state.settingsOpen = action.payload;
    },
    setIsLocalUserPresenting: (state, action: PayloadAction<boolean>) => {
      state.isLocalUserPresenting = action.payload;
    },
    setLandScapeModeOn: (state, action: PayloadAction<boolean>) => {
      state.landScapeModeOn = action.payload;
    },
    setShowDemoPopup: (state, action: PayloadAction<boolean>) => {
      state.showDemoPopup = action.payload;
    },
    setDemoPopupShown: (state, action: PayloadAction<boolean>) => {
      state.demoPopupShown = true;
    },
    setDemoMaximised: (state, action: PayloadAction<boolean>) => {
      state.isDemoMaximised = action.payload;
    },
  },
});

export const {
  setSettingsOpen,
  setIsLocalUserPresenting,
  setLandScapeModeOn,
  setShowDemoPopup,
  setDemoPopupShown,
  setDemoMaximised,
} = demoSlice.actions;

export default demoSlice.reducer;
