import axios from 'axios';

export const BASE_URL = process.env.REACT_APP_HH_API;

export async function feedbackUploadFile(
  file: any,
  progressCB: any,
  feedbackToken: string
) {
  try {
    const result = await axios.post(
      `${BASE_URL}/hunchVue/upload/${feedbackToken}`,
      file,
      {
        headers: {
          'Content-Type':
            'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW',
        },
        onUploadProgress: progressCB,
      }
    );
    return result.data;
  } catch (error) {
    return Promise.reject(error);
  }
}
