import { emailValidationRegex } from '../../constants/regex';

export const validateEmail = (emailId: string) => {
  if (!String(emailId).toLowerCase().match(emailValidationRegex)) {
    return false;
  }
  return true;
};

export const defaultThemeProps = {
  primary_color: '#4738F5',
  secondary_color: '#ff5c2e',
};

export const BrandDetailsLocation = {
  CANDIDATE_SLOT_REQUEST: 'CANDIDATE_SLOT_REQUEST',
  SHARE_FEEDBACK_REPORT: 'SHARE_FEEDBACK_REPORT',
  HUNCHVUE: 'HUNCHVUE',
};

export function setFavicons(imageUrl: string, primaryColor: string) {
  const icons = document.querySelectorAll('link[rel="icon"]');
  // for now, we are setting url link as received from back end
  icons.forEach((icon) => ((icon as HTMLLinkElement).href = imageUrl));

  // const canvas = document.createElement('canvas');
  // const ctx = canvas.getContext('2d');

  // if (!ctx) {
  //   console.error('Failed to get canvas context');
  //   return;
  // }

  // const img = new Image();
  // img.src = imageUrl;
  // img.crossOrigin = 'Anonymous';

  // img.onload = () => {
  //   // Set canvas size to the image size
  //   canvas.width = img.width;
  //   canvas.height = img.height;

  //   // Draw the image
  //   ctx.drawImage(img, 0, 0);

  //   // Get image data
  //   const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
  //   const data = imageData.data;

  //   // Loop through each pixel
  //   for (let i = 0; i < data.length; i += 4) {
  //     const alpha = data[i + 3]; // Alpha channel
  //     if (alpha === 0) {
  //       // Transparent pixel
  //       data[i] = parseInt(primaryColor.slice(1, 3), 16); // R
  //       data[i + 1] = parseInt(primaryColor.slice(3, 5), 16); // G
  //       data[i + 2] = parseInt(primaryColor.slice(5, 7), 16); // B
  //     } else {
  //       // Non-transparent pixel
  //       data[i] = 255; // R
  //       data[i + 1] = 255; // G
  //       data[i + 2] = 255; // B
  //     }
  //     data[i + 3] = 255;
  //   }

  //   // Put modified image data back to the canvas
  //   ctx.putImageData(imageData, 0, 0);

  //   // Create favicon from canvas
  //   const faviconUrl = canvas.toDataURL('image/png');
  //   icons.forEach((icon) => ((icon as HTMLLinkElement).href = faviconUrl));
  // };

  // img.onerror = () => {
  //   icons.forEach((icon) => ((icon as HTMLLinkElement).href = imageUrl));
  // };
}

export function setCustomThemes(
  primaryColor = defaultThemeProps.primary_color,
  secondaryColor = defaultThemeProps.secondary_color
) {
  if (
    primaryColor !== defaultThemeProps.primary_color ||
    secondaryColor !== defaultThemeProps.secondary_color
  ) {
    document.documentElement.style.setProperty('--ui-primary', primaryColor);

    document.documentElement.style.setProperty(
      '--ui-secondary',
      secondaryColor
    );

    const event = new CustomEvent('updateTheme', {
      detail: {
        primary_color: primaryColor,
        secondary_color: secondaryColor,
      },
    });

    // setTimeout(() => {
    document.dispatchEvent(event);
    // }, 500);
  }
}
