import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Add more types of popup here
export enum popupEnum {
  JOYRIDE = 'JOYRIDE',
  RECORD = 'RECORD',
  NOTES = 'NOTES',
  TAGS = 'TAGS',
  NONE = 'NONE',
}

interface activePopupType {
  isActive: boolean;
  popupType: popupEnum;
}

const initialState: activePopupType = {
  isActive: false,
  popupType: popupEnum.NONE,
};

export const activePopupSlice = createSlice({
  name: 'activePopup',
  initialState,
  reducers: {
    setActivePopup: (state, action: PayloadAction<popupEnum>) => {
      return {
        isActive: true,
        popupType: action.payload,
      };
    },
    setPopupDisabled: (state) => {
      return {
        isActive: false,
        popupType: popupEnum.NONE,
      };
    },
  },
});

export const { setActivePopup, setPopupDisabled } = activePopupSlice.actions;

export default activePopupSlice.reducer;
