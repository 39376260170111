import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserEnum } from '../../types/UserType';
import { VersionType } from '../../types/Versions';
import { EventV4Type } from '../../types/EventType.model';
import { CustomSnackbarProps } from '../../components/CustomSnackbar';

const defaultPopupState = {
  open: false,
  title: '',
  primaryCtaText: '',
  secondaryCtaText: '',
  description: '',
};

export enum joinedTimeEnum {
  INTERVIEWER_JOIN_TIME = 'interviewerJoinedTime',
  CANDIDATE_JOIN_TIME = 'candidateJoinedTime',
  OPERATION_JOIN_TIME = 'operationsJoinedTime',
  MEETING_START_TIME = 'meetingStartTime',
}

interface AuthState {
  userType: UserEnum;
  userId: string;
  name: string;
  email: string;
  isAuthenticated: boolean;
  token: string;
  resumeUrl: string;
  feedbackFormUrl: string;
  startTime: string;
  version: VersionType;
  internal: boolean;
  recordVideo: boolean;
  isMeetingAdmin: boolean;
  isDemo: boolean;
  companyCurrentSaasPlan: string;
  companyCountry: string;
  hunchvueNotesServiceAvailable: boolean;
  freeTrial: boolean;
  companyRecommendedQuestions: boolean;
  isOpen: boolean;
  isConsultancy: boolean;
  openInterview: boolean;
  defaultFeedbackType: boolean;
  internalInterview: boolean;
  interviewId: string;
  feedbackToken: string;
  openInterviewData: {
    minInterviewTime: number;
    maxInterviewTime: number;
    extensionDuration: number;
  };
  openPopup: {
    open: boolean;
    title: string;
    primaryCtaText: string;
    secondaryCtaText: string;
    description: string;
  };
  interviewerJoinedTime: number;
  candidateJoinedTime: number;
  events: EventV4Type[];
  showEditorShortcut: Boolean;
  interviewerSharePost: boolean;
  candidateSharePost: boolean;
  snackbar: CustomSnackbarProps;
  toggleFeedback: boolean;
  enableAutoSave: boolean;
  companyLogo: string;
  companyName: string;
  companyWebsite: string;
  companyFavicon: string;
  privacyPolicyUrl: string;
  termsOfServiceUrl: string;
  primaryColor: string;
}

const initialState: AuthState = {
  userType: UserEnum.DEFAULT,
  userId: '',
  name: '',
  email: '',
  isAuthenticated: false,
  token: '',
  resumeUrl: '',
  feedbackFormUrl: '',
  startTime: '',
  version: 'v4',
  internal: false,
  recordVideo: true,
  isMeetingAdmin: false,
  isDemo: false,
  companyCurrentSaasPlan: '',
  companyCountry: '',
  hunchvueNotesServiceAvailable: false,
  isConsultancy: false,
  companyLogo: '',
  companyName: '',
  companyWebsite: '',
  companyFavicon: '',
  primaryColor: '#4738f5',
  privacyPolicyUrl: '',
  termsOfServiceUrl: '',
  freeTrial: false,
  companyRecommendedQuestions: false,
  isOpen: false,
  openInterview: false,
  internalInterview: false,
  defaultFeedbackType: false,
  toggleFeedback: false,
  enableAutoSave: true,
  feedbackToken: '',
  interviewId: '',
  openInterviewData: {
    minInterviewTime: 30,
    maxInterviewTime: 40,
    extensionDuration: 10,
  },
  interviewerJoinedTime: -1,
  candidateJoinedTime: -1,
  openPopup: defaultPopupState,
  events: [],
  showEditorShortcut: false,
  interviewerSharePost: false,
  candidateSharePost: false,
  snackbar: {
    type: 'info' as CustomSnackbarProps['type'],
    open: false,
    message: '',
    onClose: null,
  },
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserType: (state, action: PayloadAction<UserEnum>) => {
      state.userType = action.payload;
    },
    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setToggleFeedback: (state, action: PayloadAction<boolean>) => {
      state.toggleFeedback = action.payload;
    },
    setEnableAutoSave: (state, action: PayloadAction<boolean>) => {
      state.enableAutoSave = action.payload;
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
    setInternalInterview: (state, action: PayloadAction<boolean>) => {
      state.internalInterview = action.payload;
    },
    setDefaultFeedbackType: (state, action: PayloadAction<boolean>) => {
      state.defaultFeedbackType = action.payload;
    },
    setFeedbackToken: (state, action: PayloadAction<string>) => {
      state.feedbackToken = action.payload;
    },
    setInterviewId: (state, action: PayloadAction<string>) => {
      state.interviewId = action.payload;
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setResumeUrl: (state, action: PayloadAction<string>) => {
      state.resumeUrl = action.payload;
    },
    setFeedbackFormUrl: (state, action: PayloadAction<string>) => {
      state.feedbackFormUrl = action.payload;
    },
    setStartTime: (state, action: PayloadAction<string>) => {
      state.startTime = action.payload;
    },
    setVersion: (state, action: PayloadAction<AuthState['version']>) => {
      state.version = action.payload;
    },
    setInternal: (state, action: PayloadAction<boolean>) => {
      state.internal = action.payload;
    },
    setRecordVideo: (state, action: PayloadAction<boolean>) => {
      state.recordVideo = action.payload;
    },
    setConsultancy: (state, action: PayloadAction<boolean>) => {
      state.isConsultancy = action.payload;
    },
    setUserId: (state, action: PayloadAction<string>) => {
      state.userId = action.payload;
    },
    setCompanyLogo: (state, action: PayloadAction<string>) => {
      state.companyLogo = action.payload;
    },
    setCompanyName: (state, action: PayloadAction<string>) => {
      state.companyName = action.payload;
    },
    setCompanyWebsite: (state, action: PayloadAction<string>) => {
      state.companyWebsite = action.payload;
    },
    setTermsOfServiceUrl: (state, action: PayloadAction<string>) => {
      state.termsOfServiceUrl = action.payload;
    },
    setPrivacyPolicyUrl: (state, action: PayloadAction<string>) => {
      state.privacyPolicyUrl = action.payload;
    },
    setCompanyFavicon: (state, action: PayloadAction<string>) => {
      state.companyFavicon = action.payload;
    },
    setPrimaryColor: (state, action: PayloadAction<string>) => {
      state.primaryColor = action.payload;
    },
    setIsMeetingAdmin: (state, action: PayloadAction<boolean>) => {
      state.isMeetingAdmin = action.payload;
    },
    setIsDemo: (state, action: PayloadAction<boolean>) => {
      state.isDemo = action.payload;
    },
    setCompanyCurrentSaasPlan: (state, action: PayloadAction<string>) => {
      state.companyCurrentSaasPlan = action.payload;
    },
    setCompanyCountry: (state, action: PayloadAction<string>) => {
      state.companyCountry = action.payload;
    },
    setIsCompanyOnFreeTrial: (state, action: PayloadAction<boolean>) => {
      state.freeTrial = action.payload;
    },
    setIsCompanyRecommendedQuestions: (state, action) => {
      state.companyRecommendedQuestions = action.payload.value;
    },
    setIsHunchVueNotesServiceAvailable: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.hunchvueNotesServiceAvailable = action.payload;
    },
    setOpenInterviewData: (
      state,
      action: PayloadAction<{
        minInterviewTime: number;
        maxInterviewTime: number;
        extensionDuration: number;
      }>
    ) => {
      state.openInterview = true;
      state.openInterviewData = action.payload;
    },
    setPopupData: (
      state,
      action: PayloadAction<{
        open: boolean;
        title: string;
        primaryCtaText: string;
        secondaryCtaText: string;
        description: string;
      }>
    ) => {
      state.openPopup = action.payload;
    },
    closeThePopup: (state) => {
      state.openPopup = defaultPopupState;
    },
    setEvents: (state, action: PayloadAction<EventV4Type[]>) => {
      state.events = action.payload;
    },
    setShowShortcuts: (state, action: PayloadAction<Boolean>) => {
      state.showEditorShortcut = action.payload;
    },
    setSharePost: (
      state,
      action: PayloadAction<{
        candidateSharePost: boolean;
        interviewerSharePost: boolean;
      }>
    ) => {
      state.candidateSharePost = action.payload.candidateSharePost;
      state.interviewerSharePost = action.payload.interviewerSharePost;
    },
    setSnackBar: (
      state,
      action: PayloadAction<{
        type: CustomSnackbarProps['type'];
        open: boolean;
        message: string;
      }>
    ) => {
      state.snackbar = { ...state.snackbar, ...action.payload };
    },
  },
});

export const {
  setUserType,
  setName,
  setEmail,
  setIsCompanyRecommendedQuestions,
  setIsAuthenticated,
  setToken,
  setFeedbackFormUrl,
  setEnableAutoSave,
  setResumeUrl,
  setStartTime,
  setVersion,
  setUserId,
  setInternal,
  setToggleFeedback,
  setInternalInterview,
  setDefaultFeedbackType,
  setFeedbackToken,
  setCompanyLogo,
  setPrivacyPolicyUrl,
  setTermsOfServiceUrl,
  setCompanyName,
  setCompanyWebsite,
  setCompanyFavicon,
  setPrimaryColor,
  setInterviewId,
  setRecordVideo,
  setIsMeetingAdmin,
  setIsDemo,
  setConsultancy,
  setCompanyCurrentSaasPlan,
  setCompanyCountry,
  setIsHunchVueNotesServiceAvailable,
  setIsCompanyOnFreeTrial,
  setOpenInterviewData,
  setPopupData,
  closeThePopup,
  setEvents,
  setShowShortcuts,
  setSharePost,
  setSnackBar,
} = authSlice.actions;

export default authSlice.reducer;
