import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: {
  tags: string[];
  tagsLoaded: boolean;
} = {
  tags: [],
  tagsLoaded: false,
};

export const tagSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    setTags: (state, action: PayloadAction<string[]>) => {
      return {
        tags: action.payload,
        tagsLoaded: true,
      };
    },
  },
});

export const { setTags } = tagSlice.actions;

export default tagSlice.reducer;
