import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EventType, TypeOfEvent } from '../../types/EventType.model';

interface PlaybackState {
  events: EventType[];
}

const initialState: PlaybackState = {
  events: [],
};

export const playbackSlice = createSlice({
  name: 'playback',
  initialState,
  reducers: {
    setEvents: (state, action: PayloadAction<PlaybackState['events']>) => {
      const uptoEnd = [];
      const events = action.payload;

      for (const item of events) {
        uptoEnd.push(item);
        if (item.type === TypeOfEvent.INTERVIEWENDED) break;
      }
      state.events = uptoEnd;
    },
  },
});

export const { setEvents } = playbackSlice.actions;

export default playbackSlice.reducer;
