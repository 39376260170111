import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IChatMessage } from '../../types/Chat.model';

interface ChatState {
  isOpen: boolean;
  messages: IChatMessage[];
  newMessageAvailable: Boolean;
  hasOpenedChatOnce: Boolean;
}

const initialState: ChatState = {
  isOpen: false,
  messages: [],
  newMessageAvailable: false,
  hasOpenedChatOnce: false,
};

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    openChat: (state) => {
      state.isOpen = true;
      state.hasOpenedChatOnce = true;
      state.newMessageAvailable = false;
    },
    closeChat: (state) => {
      state.isOpen = false;
    },
    setMessages: (state, action: PayloadAction<ChatState['messages']>) => {
      state.messages = action.payload;
    },
    setMessageAvailable: (state, action: PayloadAction<Boolean>) => {
      state.newMessageAvailable = action.payload;
    },
  },
});

export const { openChat, closeChat, setMessages, setMessageAvailable } =
  chatSlice.actions;

export default chatSlice.reducer;
