import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface DrawState {
  elements: any[];
}

const initialState: DrawState = {
  elements: [],
};

export const drawSlice = createSlice({
  name: 'draw',
  initialState,
  reducers: {
    setElements: (state, action: PayloadAction<DrawState['elements']>) => {
      state.elements = action.payload;
    },
  },
});

export const { setElements } = drawSlice.actions;

export default drawSlice.reducer;
